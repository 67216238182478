import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import AdvantagesItem from 'components/AdvantagesItem';
import advantages from './advantages';

const styles = () => ({
	root: {
		marginTop: '5.625rem'
	},
});

const AdvantagesList = ({
	                        classes,
                        }) => (
	<div className={classes.root}>
		<Grid container justify="space-between">
			{
				advantages.map((item, index) => (
					<Grid item xs={12} sm={3}  className={classes.item} key={index}>
						<AdvantagesItem
							title={item.title}
							text={item.text}
						/>
					</Grid>
				))
			}
		</Grid>
	</div>
);

export default withStyles(styles)(AdvantagesList);

