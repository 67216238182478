import React, {Component} from 'react';
import classNames from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import Welcome from 'components/Welcome';
import About from 'components/About';
import Advantages from 'components/Advantages';
import Steps from 'components/Steps';
import Contact from 'components/Contact';
import Footer from 'components/Footer';

const styles = (theme) => ({
    root: {
        color: theme.color.black
    }
});

class Home extends Component {
    render() {
        const {classes} = this.props;

        return (
            <div className={classNames(
                'Home',
                classes.root
            )}>
                <Grid container>
                    <Grid item xs={12}>
                        <Welcome/>
                    </Grid>
                    <Grid item xs={12}>
                        <About/>
                    </Grid>
                    <Grid item xs={12}>
                        <Advantages/>
                    </Grid>
                    <Grid item xs={12} id="works">
                        <Steps/>
                    </Grid>
                    <Grid item xs={12} id="fees">
                        <Contact/>
                    </Grid>
                    <Grid item xs={12} id="contacts">
                        <Footer/>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(Home);
