import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";


const styles = (theme) => ({
	root: {
		textAlign: 'left',
		paddingBottom: '3rem',
		[theme.breakpoints.down('sm')]: {
			maxWidth: 'inherit',
		}
	},
	title: {
		fontFamily: theme.typography.fontFamily.semiBold,
		fontSize: '1.5rem',
		lineHeight: 1.67,
		letterSpacing: '0.0125rem',
		'&:after': {
			marginTop: '0.9375rem',
			content: '""',
			width: '6.875rem',
			height: '4px',
			display: 'block',
			background: theme.color.black,
		}
	},
	text: {
		fontFamily: theme.typography.fontFamily.regular,
		fontSize: '1.25rem',
		lineHeight: 1.5,
		letterSpacing: '0.0125rem',
		marginTop: '1.875rem'
	},
	container: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'center'
		},
	}
});

const AdvantagesItem = ({
	                        classes,
	                        text,
	                        title
                        }) => (
	<div className={classes.root}>
		<Grid container className={classes.container}>
			<Grid item xs={12}>
				<Typography classes={{root: classes.title}}>
					{title}
				</Typography>
				<Typography classes={{root: classes.text}}>
					{text}
				</Typography>
			</Grid>
		</Grid>
	</div>
);

export default withStyles(styles)(AdvantagesItem);

