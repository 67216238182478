import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import MaxContainer from 'hoc/MaxContainer';

import { toggleHeader } from 'actions/helper';

import logo from 'assets/icons/logo.png';
import menu from 'assets/icons/menu.svg';
import close from 'assets/icons/close.svg';

import {root} from 'routes/internal';

const styles = (theme) => ({
  root: {
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: theme.color.white,
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'flex-end'
  },
  navLink: {
    color: theme.color.black,
    textDecoration: 'none',
    cursor: 'pointer',
    listStyleType: 'none',
    padding: '1.8125rem 0 1.8125rem 0',
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily.medium,
    borderBottom: `0.5rem solid transparent`,
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 0',
      width: '5rem',
    },
    '&:hover': {
      borderBottom: `0.5rem solid ${theme.color.black}`,
    }
  },
  logo: {
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 0'
    },
  },
  mobileNav: {
    padding: 0,
    position:'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& a': {
      cursor: 'pointer',
      listStyleType: 'none',
      fontFamily: theme.typography.fontFamily.semiBold,
      transition: 'text-shadow .3s',
    },
  },
  menu: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& img':{
      cursor: 'pointer',
    }
  },
});

class Header extends Component {
  state = {
    isMenuExpanded: false
  };

  onMenuIconClick = () => {
    this.setState(prevState => ({
        isMenuExpanded: !prevState.isMenuExpanded
      }),
      () => {
        const {toggleMenu} = this.props;
        const {isMenuExpanded} = this.state;
        toggleMenu(isMenuExpanded);
      })
  };

  render() {
    const {classes} = this.props;
    const { isMenuExpanded } = this.state;

    const navigation = [
      <React.Fragment key="menus">
        <a
          className={classes.navLink}
          href='#about'
        >
          About
        </a>
        <a
          className={classes.navLink}
          href='#advantages'
        >
          Advantages
        </a>
        <a
          className={classes.navLink}
          href='#works'
        >
          How it works
        </a>
        <a
          className={classes.navLink}
          href='#fees'
        >
          Fees
        </a>
        <a
          className={classes.navLink}
          href='#contacts'
        >
          Contacts
        </a>
      </React.Fragment>
    ];

    return (
      <div className={classes.root}>
        <MaxContainer>
          <Grid item xs={12} className={classes.main}>
            <Grid container justify="space-between">
              <Grid item xs={1} className={classes.logo}>
                <NavLink to={root}>
                  <img src={logo} alt="logo"/>
                </NavLink>
              </Grid>
              <Hidden mdUp>
                <Grid item xs={8} className={classes.menu}>
                  <img onClick={this.onMenuIconClick} src={isMenuExpanded ? close : menu} alt={isMenuExpanded ? close : menu}/>
                </Grid>
                {isMenuExpanded &&
                <Grid item xs={12} className={classes.mobileNav}>
                  {navigation}
                </Grid>
                }
              </Hidden>
              <Hidden smDown>
                <Grid item xs={8} className={classes.nav}>
                  {navigation}
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </MaxContainer>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleMenu: (isExpanded) => {
    dispatch(toggleHeader(isExpanded))
  }
});

export default withStyles(styles)(withRouter(connect(null, mapDispatchToProps)(Header)));
