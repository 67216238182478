import * as validator from 'validator';

export const isEmail = (email) => {
  return validator.isEmail(email);
};

export const isValidName = (name) => {
  return validator.isLength(name, { min: 1, max: 100 })
};


export default validator;