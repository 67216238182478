import React, {Component} from 'react';

import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import MaxContainer from 'hoc/MaxContainer';

const styles = (theme) => ({
	root: {
		backgroundColor: theme.color.white
	},
	sectionSubTitle: {
		...theme.typography.sectionSubTitle
	}
});

class About extends Component {

	render() {
		const {classes} = this.props;
		return (
			<div className={classes.root}>
				<MaxContainer>
					<Grid item xs={12}>
						<Typography classes={{root: classes.sectionSubTitle}}>
							Scrooge Bot handles trading for you. Simply connect Scrooge Bot <br/> to your Binance account and watch
							your assets grow.
						</Typography>
					</Grid>
				</MaxContainer>
			</div>
		)
	}
}

export default withStyles(styles)(About);
