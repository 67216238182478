import { createAction } from 'redux-actions';

export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const TOGGLE_HEADER = 'TOGGLE_HEADER';

export const openSnackbar = createAction(OPEN_SNACKBAR);
export const closeSnackbar = createAction(CLOSE_SNACKBAR);

export const toggleHeader = createAction(TOGGLE_HEADER);
