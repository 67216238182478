import React, {Component} from 'react';

import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import networkFooter from 'assets/images/network-footer.png';

import MaxContainer from 'hoc/MaxContainer';


const styles = (theme) => ({
    root: {
        background: `url('${networkFooter}')`,
        backgroundPosition: 'right top',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.color.black,

    },
    footerSection: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '3rem 0 2.8rem',
        flexDirection: 'column',
        position: 'relative'
    },
    sectionTitle: {
        ...theme.typography.sectionTitle,
        color: theme.color.white,
        margin: 0,
        marginBottom: '2rem'
    },
    footerContact: {
        fontFamily: theme.typography.fontFamily.medium,
        fontSize: '1.125rem',
        letterSpacing: '0.01875rem',
        color: theme.color.white,
        lineHeight: 1.5,
        marginBottom: '0.625rem',
        '& a': {
            textDecoration: 'none',
            color: theme.color.white,
        },
        '&:last-child': {
            margin: 0
        }
    },
    footerCopyright: {
        padding: '1rem 0 1.8rem',
    },
    copyrightText: {
        fontFamily: theme.typography.fontFamily.medium,
        fontSize: '0.9rem',
        letterSpacing: '0.01875rem',
        color: theme.color.white,
        lineHeight: 1.5,
        padding: '1rem 0 1.8rem',
        marginBottom: '0.625rem',
        '& a': {
            textDecoration: 'none',
            color: theme.color.white,
        },
        '&:last-child': {
            margin: 0
        }
    }
});

class Footer extends Component {

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <MaxContainer>
                    <Grid container>
                        <Grid item xs={12} className={classes.footerSection}>
                            <Typography classes={{root: classes.sectionTitle}}>
                                Contacts
                            </Typography>
                            <Typography classes={{root: classes.footerContact}}>
                                <a href="mailto:info@scroogebot.co">
                                    info@scroogebot.co
                                </a>
                            </Typography>
                            <Typography classes={{root: classes.footerContact}}>
                                <a href="address:68 Jay Street; Brooklyn NY; USA 11214">
                                    68 Jay Street, Brooklyn NY, USA 11214
                                </a>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.footerCopyright}>
                            <Typography classes={{root: classes.copyrightText}}>
                                <a href="address:68 Jay Street; Brooklyn NY; USA 11214">
                                    © Copyright 2019 ScroogeBot
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                </MaxContainer>
            </div>
        )
    }
}

export default withStyles(styles)(Footer);
