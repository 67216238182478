import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

import { closeSnackbar } from 'actions/helper';

const styles = (theme) => ({
	close: {
		padding: theme.spacing.unit / 2,
	},
	snackBar: {
		backgroundColor: theme.color.yellow,
		color: theme.color.gray,
	}
});

class NotificationSnackbar extends Component {
	state = {
		open: false,
		text: '',
		vertical: 'top',
		horizontal: 'right',
	};

	componentWillReceiveProps(nextProps) {
		if (this.props.isSnackbarOpen !== nextProps.isSnackbarOpen) {
			this.setState({
				open: nextProps.isSnackbarOpen,
				text: nextProps.snackbarText
			});
		}
	}

	render() {
		const { vertical, horizontal, open, text } = this.state;
		const { classes } = this.props;

		return (
			<Snackbar
				autoHideDuration={2000}
				anchorOrigin={{vertical, horizontal}}
				open={open}
				onClose={this.props.closeSnackbarPanel}
				ContentProps={{
					'aria-describedby': 'message-id',
					classes: {
						root: classes.snackBar,
					},
				}}
				message={<span id="message-id">{text}</span>}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	isSnackbarOpen: state.helper.isSnackbarOpen,
	snackbarText: state.helper.text
});

const mapDispatchToProps = (dispatch) => ({
	closeSnackbarPanel: () => {
		dispatch(closeSnackbar())
	}
});


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NotificationSnackbar));

