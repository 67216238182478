import React, {Component} from 'react';

import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import AdvantagesList from 'components/AdvantagesList';
import MaxContainer from 'hoc/MaxContainer';

const styles = (theme) => ({
	root: {
		paddingTop: '6.375rem',
		backgroundColor: theme.color.white,
		paddingBottom: '3.25rem'
	},
	title: {
		margin: '5rem 0 4.0625rem',
		textAlign: 'left',
		fontFamily: theme.typography.fontFamily.bold,
		fontSize: '2.75rem',
		letterSpacing: '0.125rem',
		color: theme.color.black
	},
	sectionTitle: {
		...theme.typography.sectionTitle
	}
});

class Advantages extends Component {

	render() {
		const {classes} = this.props;
		return (
			<div className={classes.root}>
				<MaxContainer>
					<Grid container>
						<Grid item xs={12}>
							<Typography classes={{root: classes.title}} id="advantages">
								Advantages
							</Typography>
							<AdvantagesList/>
						</Grid>
					</Grid>
				</MaxContainer>
			</div>
		)
	}
}

export default withStyles(styles)(Advantages);
