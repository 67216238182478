const advantages = [
	{
		title: 'Profits',
		text: 'Up to +10% per month on average'
	},
	{
		title: 'Simplicity',
		text: 'Simply provide your Binance account keys, and watch your assets grow. Scrooge applies the best trading strategies for you\n'
	},
	{
		title: 'Security',
		text: 'Your assets can’t leave your Binance account. We apply top-notch industry security standards'
	}
];

export default advantages;