import React, {Component} from 'react';
import { connect } from 'react-redux';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Button from 'components/Button';

import { isEmail, isValidName } from 'utils/validation';
import { sendGetInTouchMessageRequest } from 'actions/message';

const styles = theme => ({
  root: {
    maxWidth: '21.875rem'
  },
  button: {
    width: '100%'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  textField: {
    paddingLeft: 0,
    marginBottom: '1rem',
    color: theme.color.black,
    '&:hover': {
      borderBottom: 'none'
    },
  },
  label: {
    paddingBottom: '0.8rem',
    overflow: 'hidden',
    color: theme.color.black,
    fontSize: '1.4375rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
    },
    '&$focused': {
      color: theme.color.black,
      border: 'none'
    }
  },
  focused: {},
  underline: {
    '&&&&:hover:before': {
      borderBottom: `4px solid ${theme.color.black}`,
    },
    '&:after': {
      borderBottom: `2px solid ${theme.color.black}`,
    },
    '&:before': {
      borderBottom: `2px solid ${theme.color.black}`,
    },
  },
  formControl: {
    left: '1rem'
  },
  helperText: {
    margin: 0,
    color: theme.color.red
  },
});

class ContactForm extends Component {
  state = {
    username: '',
    email: '',
    details: 'Hi, I want to become a client of Scrooge Bot. Please contact me!',
    isUserNameValid: true,
    isUserEmailValid: true
  };

  handleTextChange = (field) => (event) => {
    this.setState({
      [field]: event.target.value,
      isUserNameValid: true,
      isUserEmailValid: true,
    });
  };

  validateInputData = (event) => {
    event.preventDefault();

    const {
      username,
      email,
      details
    } = this.state;

    const { sendMessageRequest } = this.props;


    const isUserNameValid = isValidName(username);
    const isUserEmailValid = isEmail(email);

    this.setState({
      isUserNameValid,
      isUserEmailValid,
    });


    if (isUserNameValid && isUserEmailValid) {
      sendMessageRequest({
        email,
        username,
        details
      });

      this.setState(({
        username: '',
        email: '',
      }))
    }
  };

  render() {
    const {classes} = this.props;
    const {
      username,
      email,
      isUserNameValid,
      isUserEmailValid
    } = this.state;

    return (
      <Grid item xs={12} className={classes.root}>
        <form onSubmit={this.validateInputData}>
          <Grid container>
            <Grid item xs={12} className={classes.input}>
              <TextField
                name="username"
                onChange={this.handleTextChange("username")}
                label="Name"
                value={username}
                helperText={
                  <span className={classes.helperText}>
                    {isUserNameValid ? '' : 'Provide a valid name'}
                  </span>
                }
                error={!isUserNameValid}
                fullWidth
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focused,
                    formControl: classes.formControl
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focused,
                    underline: classes.underline,
                  },
                }}
                classes={{
                  root: classes.textField,
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.input}>
              <TextField
                name="email"
                onChange={this.handleTextChange("email")}
                label="E-mail"
                value={email}
                helperText={
                  <span className={classes.helperText}>
                    {isUserEmailValid ? '' : 'Provide a valid e-mail'}
                  </span>
                }
                error={!isUserEmailValid}
                type="email"
                fullWidth
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focused,
                    formControl: classes.formControl
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focused,
                    underline: classes.underline,
                  },
                }}
                classes={{
                  root: classes.textField,
                }}
              />

            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={this.validateInputData}
                type="submit"
                classes={{button: classes.button}}
                buttonText="Contact us to apply"/>
            </Grid>
          </Grid>
        </form>
      </Grid>
    );
  };
}

const mapDispatchToProps = dispatch => ({
  sendMessageRequest: (message) => {
    dispatch(sendGetInTouchMessageRequest(message));
  }
});

export default withStyles(styles)(connect(null,  mapDispatchToProps)(ContactForm));
