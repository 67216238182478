import apiClient from './';

export const sendGetInTouchMail = ({
	                                   email,
	                                   username,
	                                   details
                                   }) => {
	return apiClient.post(`/mail/send/get-in-touch`, {
		email,
		username,
		details
	});
};