import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const color = {
  white: '#fff',
  black: '#000',
  gray: '#e8ecf1',
  red: '#f44336',
};

const typography = {
  sectionTitle: {
    margin: '9.375rem 0 4.0625rem',
    fontFamily: 'Montserrat-Bold',
    fontSize: '2.75rem',
    letterSpacing: '0.0625rem',
    color: color.black,
    textAlign: 'left',
    '@media (max-width: 600px)': {
      margin: '5.375rem 0 3.0625rem',
      fontSize: '2rem',
    },
  },
  sectionSubTitle: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: '1.5rem',
    letterSpacing: '0.0125rem',
    color: color.black,
    textAlign: 'left',
    '@media (max-width: 600px)': {
      fontSize: '1.2rem',
    },
  },
  fontFamily: {
    bold: 'Montserrat-Bold',
    regular: 'Montserrat-Regular',
    medium: 'Montserrat-Medium',
    semiBold: 'Montserrat-SemiBold',
  },
  useNextVariants: true
};

const theme = createMuiTheme({
  typography,
  color
});

export default theme;