import React, {Component} from 'react';

import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import networkWhite from 'assets/images/network-white.png';
import networkFooter from 'assets/images/network-footer.png';

import ContactForm from 'components/ContactForm';
import MaxContainer from 'hoc/MaxContainer';

const styles = (theme) => ({
	root: {
		background: `url('${networkWhite}')`,
		backgroundPosition: 'right bottom',
		backgroundRepeat: 'no-repeat',
		backgroundColor: theme.color.gray,
	},
	sectionTitle: {
		...theme.typography.sectionTitle,
		marginTop: 0
	},
	sectionSubTitle: {
		...theme.typography.sectionSubTitle,
		marginBottom: '3rem'
	},
	sectionCursive:{
		fontFamily: theme.typography.fontFamily.regular,
		marginBottom: '6.25rem',
		fontStyle: 'italic',
		fontSize: '1rem',
		textAlign: 'left'
	},
	footerSection: {
		backgroundColor: theme.color.black,
		background: `url('${networkFooter}')`,
		backgroundPosition: 'right bottom',
		backgroundRepeat: 'no-repeat',
		display: 'flex',
		alignItems: 'flex-start',
		padding: '3rem 0 2.8rem',
		flexDirection: 'column',
		position: 'relative'
	},
	footerTitle: {
		...theme.typography.sectionTitle,
		color: theme.color.white,
		margin: 0,
		marginBottom: '2.5rem'
	},
	formSection: {
		padding: '4.375rem 0',
	},
	footerContact: {
		fontFamily: theme.typography.fontFamily.medium,
		fontSize: '1.125rem',
		letterSpacing: '0.01875rem',
		color: theme.color.white,
		lineHeight: 1.5,
		marginBottom: '0.625rem',
		'&:last-child': {
			margin: 0
		}
	}
});

class Contact extends Component {

	render() {
		const {classes} = this.props;
		return (
			<div className={classes.root}>
				<MaxContainer>
					<Grid container>
						<Grid item xs={12} className={classes.formSection}>
							<Typography classes={{root: classes.sectionTitle}}>
								Fees
							</Typography>
							<Typography classes={{root: classes.sectionSubTitle}}>
								We grow if you grow. No monthly fees, we take only 30% from your profit.
							</Typography>
							<Typography classes={{root: classes.sectionCursive}}>
								*ScroogeBot is currently running in a beta mode for the first 100 users, please apply to get approved.
							</Typography>
							<ContactForm/>
						</Grid>
					</Grid>
				</MaxContainer>
			</div>
		)
	}
}

export default withStyles(styles)(Contact);
