import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import classNames from 'classnames';

const styles = (theme) => ({
  root: {
    margin: '0 auto',
    maxWidth: 1200,
    [theme.breakpoints.down('md')]: {
      padding: '0 1.25rem'
    },
  },
  container: {}
});

const MaxContainer = (props) => {
  const {
    classes,
    children,
  } = props;
  return (
    <div className={classNames('MaxContainer', classes.root)}>
        {children}
    </div>
  );
};


export default withStyles(styles)(MaxContainer);