import React, {Component, Fragment} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import Home from 'pages/Home';
import NotFound from 'pages/NotFound';

import Footer from 'components/Footer';
import Header from 'components/Header';
import NotificationSnackbar from 'components/NotificationSnackbar';

import {root} from 'routes/internal';

import './App.css';

class App extends Component {
	render() {
		return (
			<div className="App">
				<Fragment>
					<Helmet>
						<script>
							var scroll = new SmoothScroll('a[href*="#"]');
						</script>
					</Helmet>
					<header>
						<Header/>
					</header>
					<main id="main">
						<Switch>
							<Route path={root} exact component={Home}/>
							<Route component={NotFound}/>
						</Switch>
					</main>
					{/*<footer id="contacts">*/}
					{/*	<Footer/>*/}
					{/*</footer>*/}
				</Fragment>
				<NotificationSnackbar/>
			</div>
		);
	}
}

export default App;
