import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
	root: {
		padding: '1.6875rem',
		textAlign: 'left',
		border: `1px solid ${theme.color.black}`,
		maxWidth: '13.125rem',
		height: '19.375rem',
		marginBottom: '3rem'
	},
	number: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '3.125rem',
		height: '3.125rem',
		fontFamily: theme.typography.fontFamily.medium,
		fontSize: '1.75rem',
		backgroundColor: theme.color.black,
		color: theme.color.white,
		borderRadius: '50%'
	},
	text: {
		fontFamily: theme.typography.fontFamily.semiBold,
		fontSize: '1.125rem',
		lineHeight: 1.33,
		letterSpacing: '0.0125rem',
		marginTop: '3.25rem',
		color: theme.color.black
	},
	container: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'center'
		},
	},
	link: {
		textDecoration: 'none',
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline'
		}
	}
});

const StepsItem = ({
	                   classes,
	                   text,
	                   number,
	                   link
                   }) => (
	<div className='StepsItem'>
		<Grid container className={classes.container}>
			<Grid item xs={12} className={classes.root}>
				{link ? <a href={link} className={classes.link}>
						<Typography classes={{root: classes.number}}>
							{number}
						</Typography>
						<Typography classes={{root: classes.text}}>
							{text}
						</Typography>
					</a>
					:
					<div>
						<Typography classes={{root: classes.number}}>
							{number}
						</Typography>
						<Typography classes={{root: classes.text}}>
							{text}
						</Typography>
					</div>
				}
			</Grid>
		</Grid>
	</div>
);

export default withStyles(styles)(StepsItem);

