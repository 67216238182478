import React, {Component} from 'react';

import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import network from 'assets/images/network.png';

import Button from 'components/Button';
import MaxContainer from 'hoc/MaxContainer';


const styles = (theme) => ({
  root: {
    paddingTop: '3.1875rem',
    backgroundImage: `url('${network}')`
  },
  main: {
    paddingLeft: '6.25rem',
    paddingTop: '12.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '40.625rem',
    boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.color.white,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '2.25rem',
      height: '80vh',
      paddingTop: '4rem',

    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '1rem',
    },
  },
  title: {
    padding: '0 0 3.1875rem',
    fontFamily: theme.typography.fontFamily.bold,
    fontSize: '3.75rem',
    letterSpacing: '0.125rem',
    color: theme.color.black,
    textAlign: 'left',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },
  sectionTitle: {
    ...theme.typography.sectionTitle
  },
  anchor:{
    textDecoration: 'none'
  }
});

class Welcome extends Component {
  state = {};

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <MaxContainer>
          <Grid container>
            <Grid item xs={12} className={classes.main}>
              <Typography classes={{root: classes.title}}>
                Crypto trading bot
              </Typography>
              <a href='#fees' className={classes.anchor}>
                <Button buttonText="Apply to register"/>
              </a>
            </Grid>
            <Typography classes={{root: classes.sectionTitle}} id="about">
              About the Bot
            </Typography>
          </Grid>
        </MaxContainer>
      </div>
    )
  }
}

export default withStyles(styles)(Welcome);
