const steps = [
	{
		number: 1,
		text: 'Apply To Register at ScroogeBot and our team will contact you within the next 24 hours.',
		link: '#fees'
	},
	{
		number: 2,
		text: 'Register At Binance if you haven’t already. Registration guide link.',
		link: 'https://www.binance.com/register.html'
	},
	{
		number: 3,
		text: 'Obtain Binance account keys and provide them in your ScroogeBot account.'
	},
	{
		number: 4,
		text: 'Log in to your ScroogeBot account and watch your assets grow.'
	},
];

export default steps;