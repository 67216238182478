import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
	button: {
		height: 'fit-content',
		boxShadow: 'none',
		color: theme.color.white,
		background: 'transparent',
		padding: '1.25rem',
		borderRadius: 0,
		backgroundColor: theme.color.black,
		'&:hover': {
			boxShadow: '0.4375rem 0.4375rem #9b9da0',
			backgroundColor: theme.color.black,
		},
		'& span': {
			fontSize: '1.375rem',
			textTransform: 'capitalize',
			fontFamily: theme.typography.fontFamily.medium,
			letterSpacing: '0.025rem',
			[theme.breakpoints.down('sm')]: {
				fontSize: '1rem',
			}
		}
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center'
	},
});

const CustomButton = ({
	                      classes,
	                      buttonText,
	                      onClick,
	                      redirectTo
                      }) => {

	return (
		<Grid item xs={12} className={classes.buttonWrapper}>
			<Button
				variant="contained"
				classes={{root: classes.button}}
				onClick={onClick}
			>
				{redirectTo}
				{buttonText}
			</Button>
		</Grid>
	);
};


export default withStyles(styles)(CustomButton);
