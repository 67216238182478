import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import StepsItem from 'components/StepsItem';
import steps from './steps';

const styles = () => ({
	root: {
		marginTop: '5.625rem',
	}
});

const StepsList = ({
	                   classes,
                   }) => (
	<div className={classes.root}>
		<Grid container justify='space-between'>
			{
				steps.map((item, index) => (
					<Grid item xs={12} sm={6} md={3} className={classes.item} key={index}>
						<StepsItem
							number={item.number}
							text={item.text}
							link={item.link}
						/>
					</Grid>
				))
			}
		</Grid>
	</div>
);

export default withStyles(styles)(StepsList);

