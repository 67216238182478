import React, {Component} from 'react';

import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import StepsList from 'components/StepsList';
import MaxContainer from 'hoc/MaxContainer';

const styles = (theme) => ({
  root: {
    paddingTop: '6.375rem',
    backgroundColor: theme.color.white,
    paddingBottom: '3.25rem'
  },

  title: {
    textAlign: 'left',
    fontFamily: theme.typography.fontFamily.bold,
    fontSize: '2.75rem',
    letterSpacing: '0.125rem',
    color: theme.color.black
  },
  sectionTitle: {
    ...theme.typography.sectionTitle
  }
});

class Steps extends Component {

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <MaxContainer>
          <Grid container>
            <Grid item xs={12}>
              <Typography classes={{root: classes.title}}>
                How it works
              </Typography>
              <StepsList/>
            </Grid>
          </Grid>
        </MaxContainer>
      </div>
    )
  }
}

export default withStyles(styles)(Steps);
